html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  height: 100%;
}

body {
  color: #2d2d2d;
  height: 100%;
  background-color: #f3f3f3;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  overflow: hidden;
}

body * {
  box-sizing: border-box;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  color: #605096;
  margin: 0 0 12px;
}

@media (min-width: 768px) {
  h1 {
    margin-bottom: 5vh;
    font-size: 7vh;
    line-height: 7vh;
  }
}

h2 {
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

@media (min-width: 768px) {
  h2 {
    margin-bottom: 3vh;
    font-size: 5vh;
  }
}

.row {
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

.sCXHnG_container {
  width: 100%;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .sCXHnG_container {
    max-width: 150vh;
    padding: 6vh;
  }
}

.hD0OlW_rowWrap {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.hD0OlW_row {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  flex-basis: content;
  margin-left: -4vh;
  margin-right: -4vh;
  display: -ms-flexbox;
  display: flex;
}

.hD0OlW_col {
  padding-left: 4vh;
  padding-right: 4vh;
}

.hD0OlW_qrc {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.hD0OlW_infoCol {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.hD0OlW_buttonWrap {
  text-align: center;
  padding-top: 4vh;
}

.hD0OlW_buttonWrap button {
  width: 100%;
}

.c8l6sa_qrCode {
  width: 100%;
  font-size: 0;
  display: block;
}

.c8l6sa_qrCode svg {
  fill: #3f7685;
  width: 100%;
  display: block;
}

.gKNX7W_playersList {
  font-size: 4vh;
}

.gKNX7W_noPlayers {
  opacity: .5;
  text-align: center;
}

.ZGsn8a_button {
  text-transform: uppercase;
  color: #f3f3f3;
  cursor: pointer;
  background-color: #605096;
  border: none;
  border-radius: 6px;
  padding: 9px 18px;
  font-size: 24px;
  text-decoration: none;
  transition: background-color .1s ease-in-out, opacity .1s ease-in-out;
  display: inline-block;
}

.ZGsn8a_button:hover {
  background-color: #4f427b;
}

@media (min-width: 768px) {
  .ZGsn8a_button {
    border-radius: 1vh;
    padding: 1.5vh 3vh;
    font-size: 4vh;
  }
}

.ZGsn8a_disabled {
  pointer-events: none;
  opacity: .5;
}

.z40JMq_loading {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.z40JMq_loading h1 {
  margin: 0;
}

.z40JMq_dots {
  position: absolute;
}

.z40JMq_dot1 {
  animation: z40JMq_dot1 2s infinite;
}

.z40JMq_dot2 {
  animation: z40JMq_dot2 2s infinite;
}

.z40JMq_dot3 {
  animation: z40JMq_dot3 2s infinite;
}

@keyframes z40JMq_dot1 {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }
}

@keyframes z40JMq_dot2 {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }
}

@keyframes z40JMq_dot3 {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ZMux3W_caption {
  text-align: center;
  margin: 0;
  font-size: 7vh;
}

.ZMux3W_captionWrap {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.YfKtJa_stageStatus {
  min-height: 5vh;
  text-align: center;
  margin-top: 3vh;
  font-size: 5vh;
  line-height: 5vh;
}

.WyZIYq_card {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.WyZIYq_img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.WyZIYq_caption {
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 0;
  font-size: 3vh;
}

.CXK0_W_tableWrap table {
  max-width: 100vh;
  width: 100%;
  margin: 0 auto;
}

.oP-z-W_resultsTable {
  border-collapse: collapse;
  width: 100%;
  font-size: 20px;
}

@media (min-width: 768px) {
  .oP-z-W_resultsTable {
    font-size: 4vh;
  }
}

.oP-z-W_resultsTable tr:nth-child(2n-1) {
  background: #dadada;
}

.oP-z-W_resultsTable td {
  text-align: left;
  padding: 8px 16px;
}

@media (min-width: 768px) {
  .oP-z-W_resultsTable td {
    padding: 1vh 3vh;
  }
}

.oP-z-W_resultsTable td:last-child {
  text-align: right;
}

.MEndQq_preload {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

._vR06G_container {
  height: 100vh;
  text-align: center;
  width: 80%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -2vh auto 0;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  ._vR06G_container {
    margin-top: 0;
  }
}

._vR06G_menu {
  margin-top: 32px;
}

@media (min-width: 768px) {
  ._vR06G_menu {
    margin-top: 8vh;
  }
}

.UGpjbW_logo {
  text-transform: uppercase;
  width: 300px;
  margin: 0;
}

@media (min-width: 768px) {
  .UGpjbW_logo {
    width: 92vmin;
  }
}

.UGpjbW_top {
  color: #605096;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 .1em;
  font-size: 50px;
  line-height: 1.1;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .UGpjbW_top {
    font-size: 15vmin;
  }
}

.UGpjbW_bottom {
  color: #3f7685;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 115px;
  line-height: .8;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .UGpjbW_bottom {
    font-size: 35vmin;
  }
}

.pPW3gW_container {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
  display: -ms-flexbox;
  display: flex;
}

._j3jiq_login {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

._j3jiq_button {
  margin-top: 16px;
}

.U54bwG_wrap {
  display: block;
}

.U54bwG_label {
  color: #7a7a7a;
  margin-bottom: 8px;
  font-size: 14px;
  display: block;
}

.U54bwG_input {
  width: 100%;
  height: 44px;
  color: #2d2d2d;
  box-shadow: none;
  background-color: #f3f3f3;
  border: 2px solid #7a7a7a;
  border-radius: 6px;
  outline: none;
  margin: 0;
  padding: 4px 8px;
  transition: border-color .1s ease-in-out, opacity .1s ease-in-out;
}

.U54bwG_input::-ms-input-placeholder {
  opacity: .5;
  color: #7a7a7a;
}

.U54bwG_input::placeholder {
  opacity: .5;
  color: #7a7a7a;
}

.U54bwG_input:focus {
  border-color: #605096;
  outline: none;
}

._9In-RG_container {
  height: 100%;
  margin: 0 -16px;
  overflow: hidden;
}

._9In-RG_pictures {
  width: 300vw;
  height: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  left: -100vw;
  transform: translateX(0);
}

._9In-RG_picture {
  width: 100vw;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

._9In-RG_picture._9In-RG_selected ._9In-RG_overlay {
  opacity: 1;
}

._9In-RG_overlay {
  height: 100%;
  opacity: 0;
  background-color: rgba(243, 243, 243, .8);
  transition: opacity .2s ease-in-out;
  position: relative;
}

._9In-RG_overlay:after {
  content: "";
  box-sizing: border-box;
  width: 128px;
  height: 80px;
  border-bottom: 32px solid #605096;
  border-left: 32px solid #605096;
  display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: rotate(-45deg)translateX(-25%)translateY(-50%);
}

.m3uyBq_container {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.m3uyBq_buttons {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.m3uyBq_button {
  width: 88px;
  height: 88px;
  text-align: center;
  background: none;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  padding: 0;
  font-size: 68px;
  line-height: 68px;
  transition: border .1s ease-in-out;
}

.m3uyBq_button.m3uyBq_selected {
  border-color: #605096;
}

.NULlaG_container {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

/*# sourceMappingURL=index.36d25c86.css.map */
