html {
  height: 100%;
}

body {
  background-color: $c-background;
  color: $c-foreground;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.25;
  font-family: 'Montserrat', sans-serif;

  * {
    box-sizing: border-box;
  }
}

h1 {
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  text-transform: uppercase;
  color: $c-primary;

  @media ($wide) {
    font-size: 7vh;
    line-height: 7vh;
    margin-bottom: 5vh;
  }
}

h2 {
  margin: 0;
  text-transform: uppercase;
  text-align: center;

  @media ($wide) {
    font-size: 5vh;
    margin-bottom: 3vh;
  }
}
