/* COLORS */

$c-background: #f3f3f3;
$c-background-dark: darken($c-background, 10%);
$c-foreground: #2d2d2d;
$c-foreground-light: lighten($c-foreground, 30%);
$c-primary: #605096;
$c-primary-dark: darken($c-primary, 8%);
$c-secondary: #3f7685;
$c-secondary-dark: darken($c-secondary, 8%);

/* MEDIA */

$wide: 'min-width: 768px';
$mobile: 'max-width: 767px';
